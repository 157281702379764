<template>
  <AppHeader/>
  <AppContainer/>
</template>

<script>
import AppContainer from "@/components/AppContainer.vue";
import AppHeader from "@/components/AppHeader.vue";

export default {
  name: 'App',
  components: {
    AppHeader,
    AppContainer,
  }
}
</script>

<style>
body {
  background-color: #171928;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5f77c8;
  margin-top: 30px;
}
</style>
