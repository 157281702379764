<script setup>

</script>

<template>
  <div class="header">
    <v-container>
      <v-row no-gutters>
        <v-col></v-col>
        <v-col align="center" align-self="center" justify="center">
          <img id="logo" alt="Vue logo" src="../assets/logo.png">
        </v-col>
        <v-col align="right" justify="center">
          <v-btn
              variant="plain"
              class="author"
              href="https://lev.zaplatin.dev"
              target="_blank"
          >Lev Zaplatin</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.header {
  margin-bottom: 20px;
}
#logo {
  width: 150px;
  height: 150px;
}
h3 {
  font-size: 125%;
}
.author {
  font-size: 125%;
  font-weight: bold;
  color: #878aaa;
  text-transform: none;
}
</style>