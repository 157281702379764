<script setup>

</script>

<template>
  <v-item-group>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-card
              title="Tech Blog"
          >
            <template v-slot:text>
              <p>Coming soon</p>
            </template>
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-github"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://blog.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="Travel Guide"
          >
            <template v-slot:text>
              <p>
                You're traveling but don't know how to find attractions, good restaurants, bars?
                Travel Guide will help you!
              </p>
            </template>
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-web"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://travel.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="Personal Brand"
          >
            <template v-slot:text>
              <p>
                Your name is brand, your doings are your business!
                Build your brand faster and better with our platform!
              </p>
            </template>
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-web"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://brand.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="Rapidy Web Framework"
          >
            <template v-slot:text>
              <p>
                Minimalistic web framework for those who prioritize speed and convenience!
              </p>
            </template>
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-github"
                  color="#5f77c8"
                  text="Github Repository"
                  variant="outlined"
                  href="https://github.com/rAPIdy-org/rAPIdy"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="The Budget"
          >
            <template v-slot:text>
              <p>
                Simple and user-friendly app to control and manage your budget carefully and clearly!
              </p>
            </template>
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-web"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://budget.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="Planner"
          >
            <template v-slot:text>
              <p>
                Coming soon
              </p>
            </template>
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-web"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://planner.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="Game"
              text="coming soon"
          >
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-web"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://game.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card
              title="Dev Game"
              text="coming soon"
          >
            <template v-slot:actions>
              <v-btn
                  prepend-icon="mdi-web"
                  color="#5f77c8"
                  text="Web Site"
                  variant="outlined"
                  href="https://dev_game.animalprojects.dev/"
                  target="_blank"
                  block
              ></v-btn>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<style scoped>

</style>
